import React, { useContext, useEffect, useState } from 'react';
import { TwocTwopCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import {
  Button,
  buttonPayNow,
  paymentButtonProcessing,
  translationKeys
} from '@fpc/common';
import PaymentErrorMessage from '@fpc/checkout/payu/components/PaymentErrorMessage';
import {
  ALLOWED_KEYS,
  CARD_CVV_MINIMUM_LENGTH,
  CARD_NUMBER_MAXIMUM_LENGTH,
  CARD_NUMBER_MINIMUM_LENGTH,
  CARD_BRAND_PREFIXES,
  PAY_WITH_CARD_TEXT,
  SUPPORTED_CARDS_FOR_PH
} from '@fpc/checkout/twoctwop/TwocTwopConfig';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { appendTwocTwopScript } from '@fpc/checkout/twoctwop/TwocTwopLoader';
import i18n from '@fpc/common/i18n';
import i18next from 'i18next';
import CardNumberInput from '@fpc/checkout/twoctwop/components/CardNumberInput';
import CardExpiryMonthInput from '@fpc/checkout/twoctwop/components/CardExpiryMonthInput';
import CardExpiryYearInput from '@fpc/checkout/twoctwop/components/CardExpiryYearInput';
import CardCvvInput from '@fpc/checkout/twoctwop/components/CardCvvInput';
import {
  makeTwocTwopPayment,
  TwocTwopPaymentRequest
} from '@fpc/api/paymentapp/MakeTwocTwopPayment';
import { PaymentMethodType } from '../../types/payment';
import { AlternatePaymentMethods } from '@fpc/checkout/twoctwop/components/AlternatePaymentMethods';
import CardExpiryDateInput from './components/CardExpiryDateInput';

export interface TwocTwopCheckoutProps {}

export const TwocTwop: React.FC<TwocTwopCheckoutProps> = () => {
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [icons, setIcons] = useState<string[]>([]);
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cardCvv, setCardCvv] = useState('');

  const [isValidCardNumber, setValidCardNumber] = useState(false);
  const [isValidCardCvv, setValidCardCvv] = useState(false);
  const [isValidTwocTwopForm, setValidTwocTwopForm] = useState(true);

  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isPayButtonEnabled, setIsPayButtonEnabled] = useState(false);
  const [isValidExpiryDate, setisValidExpiryDate] = useState(false);

  const { transaction, tokens, errorDispatch, updatePaymentProcessing } =
    useContext(TwocTwopCheckoutContext);

  const expiryDateRef = React.useRef<HTMLInputElement>(null);

  const updateCardIcons = (): void => {
    const cardBrand = getCardBrand();
    if (cardBrand === undefined) {
      const cardBrands: string[] = SUPPORTED_CARDS_FOR_PH.map((cardBrand) => {
        return cardBrand.toLowerCase();
      });
      setIcons(cardBrands);
    } else {
      setIcons([cardBrand.toLowerCase()]);
    }
  };

  const getCardBrand = () => {
    return Object.entries(CARD_BRAND_PREFIXES).find(([brand, prefixes]) =>
      prefixes.some((prefix) => cardNumber.startsWith(prefix))
    )?.[0];
  };

  const [isAPMProcessing, setAPMProcessing] = useState(false);

  useEffect(() => {
    setIsPayButtonEnabled(
      isValidCardNumber &&
        isValidCardCvv &&
        isValidTwocTwopForm &&
        isValidExpiryDate
    );
    if (loading) {
      // Integrate Payment Token Api call
      appendTwocTwopScript()
        .then(() => {
          setLoading(false);
        })
        .catch((err: any) => {
          console.error('Error loading TwocTwop Script:', err);
          errorDispatch(ErrorCondition.Unrecoverable);
        });
    }
  }, [
    errorDispatch,
    isValidCardNumber,
    isValidCardCvv,
    isValidTwocTwopForm,
    isValidExpiryDate,
    isAPMProcessing
  ]);

  useEffect(() => {
    updateCardIcons();
  }, [cardNumber]);

  const handleWalletButtonClick = async (
    paymentMethodType: PaymentMethodType
  ) => {
    setPaymentErrorMessage('');
    const paymentRequest: TwocTwopPaymentRequest = {
      paymentInfo: JSON.parse(tokens.paymentInfoToken).paymentInfo,
      paymentMethodType: paymentMethodType,
      encryptedPaymentMethodInfo: null
    };
    await handlePayment(paymentRequest);
  };

  const handleCardFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const cardNumberInput = document.getElementById(
      'cardnumber'
    ) as HTMLInputElement;
    if (cardNumberInput) {
      cardNumberInput.value = cardNumberInput.value.replace(/\s/g, '');
    }
    if ((window as any).My2c2p) {
      (window as any).My2c2p.getEncrypted(
        '2c2p-payment-form',
        async function (
          encryptedData: {
            encryptedCardInfo: string;
          },
          errCode: number,
          errDesc: any
        ) {
          if (errCode != 0) {
            setPaymentErrorMessage(errDesc);
            setIsPayButtonEnabled(false);
            setValidTwocTwopForm(false);
          } else {
            setPaymentProcessing(true);
            setIsPayButtonEnabled(false);
            setPaymentErrorMessage('');
            setValidTwocTwopForm(true);
            const securePayToken = encryptedData.encryptedCardInfo;
            if (!securePayToken) {
              setPaymentErrorMessage(
                i18next.t(translationKeys.common.technicalErrorPayment)
              );
              setIsPayButtonEnabled(true);
              setPaymentProcessing(false);
              return;
            }
            const paymentRequest: TwocTwopPaymentRequest = {
              paymentInfo: JSON.parse(tokens.paymentInfoToken).paymentInfo,
              paymentMethodType: PaymentMethodType.CARD,
              encryptedPaymentMethodInfo: securePayToken
            };
            // Encrypt the form Integrate Handle Payment Api call
            await handlePayment(paymentRequest);
          }
        }
      );
    } else {
      setPaymentErrorMessage(
        i18next.t(translationKeys.common.technicalErrorPayment)
      );
    }
  };

  const getErrorByPaymentMethodType = (
    paymentMethodType: PaymentMethodType
  ): string => {
    switch (paymentMethodType) {
      case PaymentMethodType.CARD:
        return translationKeys.checkout.paymentNotProcessed;
      default:
        return translationKeys.common.technicalErrorPayment;
    }
  };

  const handlePayment = async (paymentRequest: TwocTwopPaymentRequest) => {
    try {
      setAPMProcessing(true);
      const response = await makeTwocTwopPayment(
        paymentRequest,
        tokens.digitalSignature!
      );
      response.redirectionUrl
        ? (window.location.href = response.redirectionUrl)
        : setPaymentErrorMessage(
            i18next.t(
              getErrorByPaymentMethodType(paymentRequest.paymentMethodType)
            )
          );
    } catch (error: any) {
      setPaymentErrorMessage(
        i18next.t(getErrorByPaymentMethodType(paymentRequest.paymentMethodType))
      );
    } finally {
      if (paymentRequest.paymentMethodType === PaymentMethodType.CARD) {
        setIsPayButtonEnabled(true);
      }
      updatePaymentProcessing('');
      setAPMProcessing(false);
      setPaymentProcessing(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;

    if (
      /^\d$/.test(key) ||
      ALLOWED_KEYS.includes(key) ||
      ((event.ctrlKey || event.metaKey) &&
        ['c', 'v', 'a'].includes(key.toLowerCase()))
    ) {
      return;
    }

    event.preventDefault();
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const { id, value } = event.target;
    const sanitizedValue = value.replace(/[^\d]/g, '');

    switch (true) {
      case id.includes('cardnumber'):
        const truncatedValue = sanitizedValue.slice(
          0,
          CARD_NUMBER_MAXIMUM_LENGTH
        );
        setCardNumber(truncatedValue);
        event.target.value = truncatedValue;

        const isValid = truncatedValue.length >= CARD_NUMBER_MINIMUM_LENGTH;
        setValidCardNumber(isValid);

        // If card number is complete (16 digits), move to expiry date
        if (truncatedValue.length === 16 && isValid && expiryDateRef.current) {
          expiryDateRef.current.focus();
        }

        setValidTwocTwopForm(true);
        setPaymentErrorMessage('');
        break;
      case id.includes('month'):
        setExpiryMonth(value);
        setPaymentErrorMessage('');
        setValidTwocTwopForm(true);
        break;
      case id.includes('year'):
        setExpiryYear(value);
        setPaymentErrorMessage('');
        setValidTwocTwopForm(true);
        break;
      case id.includes('cvv'):
        setCardCvv(value);
        setValidCardCvv(
          value !== '' && value.length >= CARD_CVV_MINIMUM_LENGTH
        );
        setPaymentErrorMessage('');
        setValidTwocTwopForm(true);
        break;
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    event.preventDefault();
    const { id, value } = event.target;
    if (id.includes('cardnumber')) {
      handleCardNumberBlur(value);
    } else if (id.includes('cvv')) {
      handleCvvBlur(value);
    }
  }

  function handleCardNumberBlur(value: string) {
    const element = document.getElementById('ccn-help');
    const sanitizedValue = value.replace(/[^\d]/g, '');

    if (element) {
      element.innerHTML =
        sanitizedValue.length < CARD_NUMBER_MINIMUM_LENGTH
          ? i18n.t<string>(translationKeys.checkout.invalidCCNMessage)
          : '';
      setValidCardNumber(sanitizedValue.length >= CARD_NUMBER_MINIMUM_LENGTH);
    }
  }

  function handleCvvBlur(value: string) {
    const element = document.getElementById('cvv-help');
    if (element) {
      element.innerHTML =
        value.length < CARD_CVV_MINIMUM_LENGTH
          ? i18n.t<string>(translationKeys.checkout.invalidCVVMessage)
          : '';
      setValidCardCvv(value.length >= CARD_CVV_MINIMUM_LENGTH);
    }
  }

  return (
    <div
      className="checkout-page"
      data-testid={'TwocTwop-checkout-form'}
      style={
        isAPMProcessing
          ? {
              opacity: '0.5',
              pointerEvents: 'none',
              backgroundColor: '#fff',
              padding: '10px'
            }
          : { backgroundColor: '#fff', padding: '10px' }
      }
    >
      <AlternatePaymentMethods
        alternativePaymentMethodTypes={
          transaction.alternativePaymentMethodTypes
        }
        handleWalletButtonClick={handleWalletButtonClick}
      />
      {transaction.alternativePaymentMethodTypes &&
        transaction.alternativePaymentMethodTypes.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '20px 0',
              gap: '15px'
            }}
            data-testid="paywithcard-text"
          >
            <div
              style={{ flex: 1, height: '1px', backgroundColor: '#E5E7EB' }}
            />
            <h4 style={{ margin: 0, color: '#6B7280' }}>
              {PAY_WITH_CARD_TEXT}
            </h4>
            <div
              style={{ flex: 1, height: '1px', backgroundColor: '#E5E7EB' }}
            />
          </div>
        )}
      <form id="2c2p-payment-form" onSubmit={handleCardFormSubmit}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: '10px',
            gridAutoRows: 'minmax(50px, auto)'
          }}
        >
          <CardNumberInput
            cardNumber={cardNumber}
            icons={icons}
            handleKeyPress={handleKeyPress}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '10px',
              gridAutoRows: 'minmax(50px, auto)'
            }}
          >
            <CardExpiryDateInput
              ref={expiryDateRef}
              expiryMonth={expiryMonth}
              expiryYear={expiryYear}
              setIsValid={setisValidExpiryDate}
              onMonthChange={(month: any) => {
                const event = {
                  target: { id: 'month', value: month },
                  preventDefault: () => {}
                } as React.ChangeEvent<HTMLInputElement>;
                handleChange(event);
              }}
              onYearChange={(year: any) => {
                const event = {
                  target: { id: 'year', value: year },
                  preventDefault: () => {}
                } as React.ChangeEvent<HTMLInputElement>;
                handleChange(event);
              }}
              onBlur={handleBlur}
            />
            <CardCvvInput
              cardCvv={cardCvv}
              handleKeyPress={handleKeyPress}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>

          {/* Hidden original inputs for form submission */}
          <div style={{ display: 'none' }}>
            <CardExpiryMonthInput
              expiryMonth={expiryMonth}
              handleKeyPress={handleKeyPress}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <CardExpiryYearInput
              expiryYear={expiryYear}
              handleKeyPress={handleKeyPress}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        {paymentErrorMessage !== '' && (
          <PaymentErrorMessage errorMessage={paymentErrorMessage} />
        )}
        <div style={{ gridArea: '3 / 1 / auto / -1' }}>
          <Button
            id="pay-button"
            disabled={!isPayButtonEnabled}
            data-testid={'pay-button'}
            style={{ marginTop: '1em' }}
            type={'submit'}
          >
            {isPaymentProcessing
              ? paymentButtonProcessing()
              : buttonPayNow(transaction.amount, transaction.currency)}
          </Button>
        </div>
      </form>
    </div>
  );
};

import {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElementConfirmEvent
} from '@stripe/stripe-js';
import { useContext } from 'react';
import { StripeCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import { isLocal } from '../../flags';
import {
  ExpressCheckoutButton,
  ExpressCheckoutOptions
} from '@fpc/common/components/ExpressCheckoutButton';
import { ConfirmationMethodParams, PaymentMethodParams } from './GuestPayment';

interface GuestPaymentRequestProps {
  elements: StripeElements;
  stripe: Stripe;
  isReadyHandler: (isReady: boolean) => void;
  setPaymentMethod: (paymentMethod: PaymentMethodParams) => void;
  setConfirmationMethod: (paymentMethod: ConfirmationMethodParams) => void;
}

interface PaymentToken {
  id: string;
  type: string;
}

export function GuestPaymentRequestButton(props: GuestPaymentRequestProps) {
  const { transaction, fordPayGuestId } = useContext(StripeCheckoutContext);

  const onExpressPaymentCallback = async (
    ev: StripeExpressCheckoutElementConfirmEvent,
    elements: StripeElements
  ): Promise<void> => {
    props.isReadyHandler(false);

    let result;
    let error;

    // @ts-ignore
    const isKlarna = ev.expressPaymentType === 'klarna';

    if (!isKlarna) {
      ({ paymentMethod: result, error } =
        await props.stripe.createPaymentMethod({ elements }));
    } else {
      ({ confirmationToken: result, error } =
        await props.stripe.createConfirmationToken({ elements }));
    }

    if (result) {
      const id = result.id;
      const type = isPaymentToken(result) ? result.type : ev.expressPaymentType;
      const setMethod = isKlarna
        ? props.setConfirmationMethod
        : props.setPaymentMethod;
      setMethod({ id, type });
    } else {
      if (isLocal) {
        console.warn(error);
      }
      ev.paymentFailed({ reason: 'fail' });
      props.isReadyHandler(true);
    }
  };

  function isPaymentToken(result: any): result is PaymentToken {
    return (result as PaymentToken).type !== undefined;
  }

  const expressCheckoutOptions: ExpressCheckoutOptions = {
    amount: transaction.amount,
    currency: transaction.currency,
    googlePay: transaction.alternativePaymentMethodTypes.includes('GOOGLE_PAY'),
    applePay: transaction.alternativePaymentMethodTypes.includes('APPLE_PAY'),
    klarna: transaction.alternativePaymentMethodTypes.includes('KLARNA'),
    isSetupMode: !!fordPayGuestId
  };

  return (
    <ExpressCheckoutButton
      stripe={props.stripe}
      options={expressCheckoutOptions}
      onPaymentConfirmCallback={onExpressPaymentCallback}
    />
  );
}

import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  determineButtonProcessingText,
  getIcon,
  LoadingSpinner,
  REQUIRES_ACTION_STATUS,
  StripeElementErrorDisplay,
  translationKeys
} from '@fpc/common';
import {
  cardElementStyle,
  loadingSpinnerBig,
  STRIPE_APPEARANCE,
  Style
} from '@fpc/common/Styles';
import { StripeCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';
import i18next from '@fpc/common/i18n';
import { TermsAndConditions } from '@fpc/common/components/TermsAndConditions';
import {
  PaymentMethodResult,
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret
} from '@stripe/stripe-js';
import {
  ACCOUNT_ID_PARAM,
  CARD_PAYMENT_METHOD,
  PAYMENT_INTENT_PARAM,
  PAYMENT_INTENT_SECRET_PARAM,
  PAYMENT_TYPE_PARAM,
  REDIRECT_STATUS_PARAM
} from '../index';
import { getFirstUrlQueryDelimiter } from '@fpc/utils/urlQueryDelimiter';

import { isLocal } from '../../flags';
import useStripePaymentElements from '@fpc/api/stripe/UseStripePaymentElements';
import { Checkbox, CheckboxShadowbox } from '@fpc/common/components/Checkbox';
import { detachPaymentMethod } from '@fpc/api/customerapp';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import { PaymentResponse } from '@fpc/api/paymentapp/MakeAuthenticatedPayment';
import {
  MIT_ELEMENT_NAME,
  MitTokens
} from '@fpc/reactutils/mitContextProvider';
import {
  dispatchPaymentStatusEvent,
  DispatchPaymentStatusEventRequest
} from '@fpc/utils/dispatchEvent';
import { callHandleNextAction } from '@fpc/api/stripe/HandleNextAction';
import { CanCloseButton } from '@fpc/common/components/CanCloseButton';
import { FordStyles } from '@fpc/common/FordStyles';
import { AttachPaymentMethod } from '@fpc/api/paymentapp';
import { attachPaymentMethod } from '@fpc/api/customerapp/AttachPaymentMethod';

interface PaymentProps {
  stripe: Stripe;
  paymentIntentClientSecret: string;
  isPreAuth: boolean;
  isAuthenticatedCheckout: boolean;
  transaction?: StripeTransactionDetails | null;
  togglePayByAcssDebit: (value: boolean) => void;
  isMit?: boolean;
  mitTokens?: MitTokens;
  mitBffUrl?: string | null;
  mitCloseButtonEnable: () => void;
  isOnlyPayByAcssDebit: boolean;
  isAcssDebitFirst: boolean;
  handleClose?: () => void;
  stripeConnectCustomerId?: string;
  isFirstCard?: boolean;
}

export const PayByAcssDebit = (props: PaymentProps) => {
  const { transaction, redirectUrl, errorDispatch, paymentMethodDisplayOrder } =
    useContext(StripeCheckoutContext);
  const [message, setMessage] = useState('');
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [isSavePaymentMethod, setIsSavePaymentMethod] = useState(false);
  const redirectTime = 2000;
  const [isPaymentProcessed, setPaymentProcessed] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const transactionDynamic = props.isMit
    ? (props.transaction as StripeTransactionDetails)
    : (transaction as StripeTransactionDetails);
  const options: StripeElementsOptionsClientSecret = {
    clientSecret: props.paymentIntentClientSecret,
    appearance: STRIPE_APPEARANCE
  };
  const [elements] = useState<StripeElements>(props.stripe.elements(options));
  const { mountPaymentElements, isPaymentMounted, isFormComplete } =
    useStripePaymentElements(
      props.stripe,
      options,
      elements,
      errorDispatch,
      paymentMethodDisplayOrder
    );

  useEffect(() => {
    mountPaymentElements('#payment-element-acss', (event) => {
      setPaymentType(event.value.type);
    });
  }, []);

  useEffect(() => {
    if (props.isMit) {
      props.mitCloseButtonEnable();
    }
  });

  useEffect(() => {
    if (isPaymentProcessed && paymentIntentId) {
      setTimeout(() => {
        window.location.href =
          redirectUrl +
          `${queryDelimiter}${ACCOUNT_ID_PARAM}=${
            (transaction as StripeTransactionDetails).merchantAccountId
          }` +
          `&${PAYMENT_INTENT_PARAM}=${paymentIntentId}&${PAYMENT_INTENT_SECRET_PARAM}=${props.paymentIntentClientSecret}` +
          `&${REDIRECT_STATUS_PARAM}=succeeded&${PAYMENT_TYPE_PARAM}=${paymentType}`;
      }, redirectTime);
    }
  }, [isPaymentProcessed]);

  const queryDelimiter = getFirstUrlQueryDelimiter(redirectUrl);

  function handleNextActionErrorCallback() {
    props?.togglePayByAcssDebit(true);
  }

  const handleCheckout = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPaymentProcessing(true);
    setMessage('');
    const stripePaymentMethod = await props.stripe.createPaymentMethod({
      elements: elements
    });
    if (!stripePaymentMethod.paymentMethod) {
      setPaymentProcessing(false);
      return;
    }

    await attachPaymentMethodWithCondition(stripePaymentMethod);

    const { error } = await props.stripe
      .confirmPayment({
        clientSecret: props.paymentIntentClientSecret,
        confirmParams: {
          payment_method: stripePaymentMethod.paymentMethod?.id
        },
        redirect: 'if_required'
      })
      .then(async (res) => {
        if (props.isMit && res?.paymentIntent) {
          const response: PaymentResponse = {
            paymentIntentClientSecret: props.paymentIntentClientSecret,
            paymentIntentId: res.paymentIntent.id,
            status: res.paymentIntent.status,
            merchantAccountId: transactionDynamic?.merchantAccountId
          };
          const request: DispatchPaymentStatusEventRequest = {
            eventTarget: MIT_ELEMENT_NAME,
            status: 'SUCCESS',
            paymentIntentId: response.paymentIntentId,
            setupIntentId: '',
            paymentMethodId: stripePaymentMethod.paymentMethod?.id,
            paymentMethodDetails: null
          };
          if (response.status === REQUIRES_ACTION_STATUS) {
            await callHandleNextAction(
              response,
              () => {
                dispatchPaymentStatusEvent(request);
                setPaymentProcessing(false);
              },
              handleNextActionErrorCallback
            );
          } else {
            dispatchPaymentStatusEvent(request);
            setPaymentProcessing(false);
          }
        } else if (res.error) {
          setPaymentProcessing(false);
          throw res.error;
        } else {
          setPaymentProcessing(false);
          setPaymentIntentId(res.paymentIntent.id);
          setPaymentProcessed(true);
        }
        return res;
      })
      .catch((err) => {
        setPaymentProcessing(false);
        detachAcssPaymentMethod(stripePaymentMethod);
        return {
          error: {
            message: i18next.t<string>(
              translationKeys.checkout.redirectStatusFailure
            )
          }
        };
      });

    if (isLocal) {
      console.warn(error);
    }
    setMessage(error?.message ?? '');
    setPaymentProcessing(true);
  };

  async function attachPaymentMethodWithCondition(
    stripePaymentMethod: PaymentMethodResult
  ) {
    if (isSavePaymentMethod) {
      let attachPayment: AttachPaymentMethod = {
        merchantAccountId: transactionDynamic?.merchantAccountId,
        paymentMethodId: stripePaymentMethod.paymentMethod?.id!,
        connectCustomerId: props.stripeConnectCustomerId!
      };
      await attachPaymentMethod(attachPayment);
    }
  }

  function toggleBacktoCardForm() {
    props.togglePayByAcssDebit(false);
  }

  async function detachAcssPaymentMethod(
    stripePaymentMethod: PaymentMethodResult
  ): Promise<void> {
    if (!isSavePaymentMethod) {
      detachPaymentMethod(
        stripePaymentMethod.paymentMethod?.id!,
        transactionDynamic?.merchantAccountId
      );
    }
  }
  const RadioIcon = getIcon(CARD_PAYMENT_METHOD);
  const cardRadioButton = (
    <div
      style={{
        textAlign: 'left',
        position: 'relative',
        padding: '15px'
      }}
    >
      <input
        type={'radio'}
        style={{ marginRight: '5px', paddingRight: '10px' }}
        onChange={() => toggleBacktoCardForm()}
      />
      <RadioIcon
        style={{
          height: '1em',
          marginRight: '15px',
          marginTop: '2px',
          paddingLeft: '5px'
        }}
      />

      <a
        href="#"
        role="link"
        data-testid={'payment-element-radio-label'}
        style={{
          color: FordStyles.color.primary,
          fontFamily: FordStyles.fontFamilyBold,
          fontSize: '12.7px',
          lineHeight: '12px',
          paddingBottom: '10px',
          textDecoration: 'none'
        }}
        onClick={() => {
          toggleBacktoCardForm();
        }}
      >
        <b> {i18next.t<string>(translationKeys.checkout.card)} </b>
      </a>
    </div>
  );
  return (
    <>
      {isPaymentMounted ? '' : <LoadingSpinner style={loadingSpinnerBig} />}
      <form
        hidden={!isPaymentMounted}
        onSubmit={handleCheckout}
        id="payment-form"
        data-testid="payment-container"
      >
        {!props.isFirstCard && (props.isAuthenticatedCheckout || props.isMit) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '0px 16px'
            }}
          >
            <h2 className={'new-payment-method'}>
              {i18next.t<string>(translationKeys.checkout.useNewPayment)}
            </h2>
            <span style={{ flexGrow: '1' }} />
            {<CanCloseButton handleClose={props.handleClose!} />}
          </div>
        )}

        {!props.isAcssDebitFirst &&
          !props.isOnlyPayByAcssDebit &&
          cardRadioButton}

        <div
          id="payment-element-acss"
          data-testid={'payment-element-acss'}
          style={{
            ...cardElementStyle,
            paddingBlockStart: '1em',
            left: '15px'
          }}
        >
          {/*Payment Element gets injected here.*/}
        </div>

        {props.isAcssDebitFirst &&
          !props.isOnlyPayByAcssDebit &&
          cardRadioButton}
        {props.isAuthenticatedCheckout ? (
          <CheckboxShadowbox>
            <Checkbox
              label={i18next.t<string>(
                translationKeys.common.savePaymentMethodCheck
              )}
              onChange={setIsSavePaymentMethod}
              isChecked={isSavePaymentMethod}
              uniqueId="save-payment-method-checkbox"
            />
            {props.isMit && (
              <div
                style={{
                  textAlign: 'start',
                  margin: '-.5em 2.725em 0 2.725em'
                }}
                hidden={isSavePaymentMethod}
              >
                <span style={{ color: Style.color.error, fontSize: '.875em' }}>
                  {i18next.t<string>(
                    translationKeys.mit.savePaymentMethodAlert
                  )}
                </span>
              </div>
            )}

            <div
              style={{ textAlign: 'start', margin: '-.5em 2.725em 0 2.725em' }}
            >
              <span style={{ color: Style.color.gray2, fontSize: '.875em' }}>
                {i18next.t<string>(translationKeys.checkout.checkoutFaster)}
              </span>
            </div>
          </CheckboxShadowbox>
        ) : (
          <></>
        )}

        <TermsAndConditions
          translationGroup={translationKeys.checkout}
          paymentMethod={paymentType}
        />
        <StripeElementErrorDisplay message={message} />
        <Button
          disabled={!isFormComplete || isPaymentProcessing}
          id="submit"
          style={{ marginTop: '0.5em' }}
        >
          {determineButtonProcessingText(
            isPaymentProcessing,
            props.isMit,
            transactionDynamic
          )}
        </Button>
      </form>
    </>
  );
};

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { traceId } from '@fpc/reactutils/TraceIdContext';

export type HttpHeaders = {
  [key: string]: string;
};

export enum HttpMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

export enum HttpHeader {
  CONTENT_TYPE = 'Content-Type',
  TRACEPARENT = 'Traceparent',
  AUTHORIZATION = 'Authorization',
  CAT_TOKEN = 'x-token',
  CAT_TOKEN_TYPE = 'x-token-type'
}

class HttpService<T = any> {
  public axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      headers: {
        [HttpHeader.CONTENT_TYPE]: 'application/json',
        [HttpHeader.TRACEPARENT]: traceId
      }
    });
  }

  public async get<R = T>(url: string, headers?: HttpHeaders): Promise<R> {
    return this.request<R>({ method: HttpMethod.GET, url, headers });
  }

  public async post<R = T>(
    url: string,
    data?: object,
    headers?: HttpHeaders
  ): Promise<R> {
    return this.request<R>({ method: HttpMethod.POST, url, data, headers });
  }

  public async put<R = T>(
    url: string,
    data?: object,
    headers?: HttpHeaders
  ): Promise<R> {
    return this.request<R>({ method: HttpMethod.PUT, url, data, headers });
  }

  public async delete<R = T>(url: string): Promise<R> {
    return this.request<R>({ method: HttpMethod.DELETE, url });
  }

  private handleError(error: unknown): Error {
    if (axios.isAxiosError(error) || error instanceof AxiosError) {
      return new Error(
        `HTTP error: ${error.response?.status} ${error.response?.statusText}`
      );
    }
    return error instanceof Error
      ? error
      : new Error('An unknown error occurred');
  }

  private async request<R = T>(config: AxiosRequestConfig): Promise<R> {
    try {
      const response: AxiosResponse<R> = await this.axiosInstance.request(
        config
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }
}

export default HttpService;
